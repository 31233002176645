<template>
  <div class="main">
    <router-link to="/shops">
      <a-button
        class="p-0 mb-3"
        type="link"
        icon="arrow-left"
        size="large">
        {{ $t('back') }} {{ $t('to') }} {{ $t('msg.platform.title') }}
      </a-button>
    </router-link>
    <div>
      <h2>{{ $t('msg.shop.update.title') }}</h2>
      <p class="my-4">
        {{ $t('msg.shop.update.subtitle') }}
      </p>
      <div class="card p-3">
        <div class="row card-body">
          <div class="col-xl-5 col-lg-12 mb-2">
            <h5>{{ $t('shop') }}</h5>
          </div>
          <div class="col-xl-7 col-lg-12">
            <a-form-model
              ref="form"
              :model="form"
              :rules="rules"
              class="mt-2">
              <j-input
                prop="name"
                icon="font-size"
                :label="$t('msg.shop.update.name')"
                v-model="form.name"/>
              <!-- Range Min -->
            </a-form-model>
            <div class="mt-1">
              <a-checkbox v-model="form.sender">
                {{ $t('msg.shop.update.sender') }}
              </a-checkbox>
              <j-help link="https://academy.jipink.com/docs/multitienda/?utm_campaign=adoption&utm_source=desk&utm_medium=help#nombre-de-tienda" />
            </div>
            <!-- Submit -->
            <div class="font-size-18 mt-4">
              <a-button
                type="primary"
                size="large"
                @click="onSubmit">
                {{ $t('msg.shop.update.submit') }}
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import v from '@/utils/validators'
import lms from '@/api/lms'
import JInput from '@/views/shared/forms/input'
import JHelp from '@/views/shared/labels/help'

export default {
  components: {
    JInput,
    JHelp,
  },
  data: function () {
    return {
      form: {
        name: null,
        sender: false,
      },
      rules: {
        name: [v.required, v.name],
      },
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      lms.account.fetchShop(this.account, this.$route.params.shop)
        .then(response => {
          this.form.name = response.data.name
          this.form.sender = response.data.use_as_sender
        })
    },
    onSubmit() {
      Promise.all([
        new Promise((resolve, reject) => this.$refs.form.validate(resolve)),
      ]).then(results => {
        if (results.every(Boolean)) {
          lms.account.updateShop(this.account, this.$route.params.shop, this.form.name, this.form.sender)
            .then(_ => this.$router.push('/shops'))
        }
      })
    },
  },
}
</script>
